import {
  FileDownload as ExportIcon,
  FileUpload as ImportIcon,
  RestartAlt as ResetIcon,
} from '@mui/icons-material'
import {
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro'

import {
  ButtonStyled,
  GridToolbarContainerStyled,
} from '@/components/table/render-custom-toolbar.styled'
import type { ExportOptions, ImportOptions } from '@/components/table/types'

const renderExport = (exportOptions: ExportOptions) => {
  return (
    <ButtonStyled onClick={exportOptions.onClick} startIcon={<ExportIcon />}>
      Export
    </ButtonStyled>
  )
}

const renderImport = (importOptions: ImportOptions) => {
  if (!importOptions) {
    return null
  }
  return (
    <ButtonStyled onClick={importOptions.onClick} startIcon={<ImportIcon />}>
      Import
    </ButtonStyled>
  )
}

type CustomToolbarProps = {
  exportOptions: ExportOptions
  importOptions: ImportOptions
  onReset: () => void
}
const CustomToolbar = ({ exportOptions, importOptions, onReset }: CustomToolbarProps) => {
  return (
    <GridToolbarContainerStyled>
      <GridToolbarQuickFilter />

      <div>
        <ButtonStyled onClick={onReset} startIcon={<ResetIcon />}>
          Reset
        </ButtonStyled>
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
        {renderExport(exportOptions)}
        {renderImport(importOptions)}
      </div>
    </GridToolbarContainerStyled>
  )
}

export { CustomToolbar }
