# November 2023

## 0.2.0

- Implement business units for shipper
- Minor adjustments to new shipment form contents
- Implement basic filters in shipment overview page
- Add close dialog button and fix overflow issue
- Bug fixes and other enhancements

## 0.1.0

- Add shipment overview page
- Add shipment form to tender new loads, modify existing loads, and cancel loads
