import { Auth0Client, AuthenticationError } from '@auth0/auth0-spa-js'

import type { AuthReturn } from '@/types'

const parseMetadata = (token: string) => {
  try {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace('-', '+').replace('_', '/')
    const data = JSON.parse(window.atob(base64))
    return data[`https://${import.meta.env.VITE_AUTH0_DOMAIN}/metadata`]
  } catch (error) {
    return undefined
  }
}

const auth = async (): Promise<AuthReturn> => {
  const domain = import.meta.env.VITE_AUTH0_DOMAIN
  const audience = import.meta.env.VITE_AUTH0_AUDIENCE
  const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID

  const client = new Auth0Client({
    authorizationParams: {
      audience,
      redirect_uri: window.location.origin,
    },
    cacheLocation: 'localstorage',
    clientId,
    domain,
  })
  try {
    const token = await client.getTokenSilently()

    return {
      client,
      isRedirectToLogin: false,
      token,
      user: parseMetadata(token),
    }
  } catch (error) {
    const e = error as AuthenticationError
    if (e.error === 'login_required') {
      await client.loginWithRedirect()
    }
    return {
      client,
      isRedirectToLogin: true,
    }
  }
}

export { auth }
