declare module '@mui/material/styles' {
  interface Palette {
    brand: Palette['primary']
  }

  interface PaletteOptions {
    brand?: Palette['primary']
  }
}

const colors = {
  actions: {
    border: '#e0e0e0',
  },
  background: {
    default: '#fafafa',
    main: '#ffffff',
  },
  brand: {
    dark: '#0c2845',
    light: '#1dd264',
  },
  deEmphasize: 'rgba(0, 0, 0, 0.38)',
  disabled: 'rgba(0, 0, 0, 0.26)',
  divider: '#e5e5e5',
  error: {
    dark: '#e31b0c',
    light: '#f88078',
    main: '#f44336',
  },
  info: {
    dark: '#0064b0',
    darkText: '#001530',
    light: '#83c1ff',
    main: '#4a90e2',
  },
  primary: {
    contrastText: '#ffffff',
    dark: '#0064b0',
    light: '#83c1ff',
    main: '#4a90e2',
  },
  secondary: {
    contrastText: '#ffffff',
    dark: '#001530',
    light: '#476587',
    main: '#183b5a',
  },
  success: {
    dark: '#3b873e',
    light: '#7bc67e',
    main: '#4caf50',
  },
  warning: {
    dark: '#c77700',
    light: '#ffb547',
    main: '#ff9800',
  },
}

const createPalette = () => {
  return colors
}

export { createPalette }
