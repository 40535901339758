import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import type { RootState } from '@/store'

const initialState = {
  isDrawerOpen: true,
}

const navigationSlice = createSlice({
  initialState,
  name: 'navigation',
  reducers: {
    setIsDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.isDrawerOpen = action.payload
    },
  },
})

const selectIsDrawerOpen = (state: RootState) => {
  return state.navigation.isDrawerOpen
}

const { setIsDrawerOpen } = navigationSlice.actions
const { reducer: navigationReducer } = navigationSlice

export { navigationReducer, selectIsDrawerOpen, setIsDrawerOpen }
