import { Grid, css, styled } from '@mui/material'

const GridStyled = styled(Grid)<{
  $height: number
  $offset: number
  $overlay: boolean
}>`
  align-content: center;
  justify-content: center;

  height: ${({ $height, $offset }) => {
    return !$height ? `calc(100vh - ${$offset})` : $height
  }};

  ${({ $overlay }) => {
    if ($overlay) {
      return css`
        position: absolute;
        height: 100%;
        z-index: 10;
        background-color: rgba(1, 1, 1, 0.25);
      `
    }
    return ``
  }};
`

export { GridStyled }
