import { memo } from 'react'

import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { IconButton } from '@mui/material'

import { FooterStyled, ToggleDrawerStyled } from '@/navigation/drawer-footer.styled'
import type { Noop } from '@/types'

type DrawerFooterProps = {
  isDrawerOpen: boolean
  toggleDrawer: Noop
}
const DrawerFooter = ({ isDrawerOpen, toggleDrawer }: DrawerFooterProps) => {
  return (
    <FooterStyled>
      <ToggleDrawerStyled $isDrawerOpen={isDrawerOpen}>
        <IconButton onClick={toggleDrawer}>
          {isDrawerOpen ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      </ToggleDrawerStyled>
    </FooterStyled>
  )
}

const Memo = memo(DrawerFooter)
export { Memo as DrawerFooter }
