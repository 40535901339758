import { CircularProgress } from '@mui/material'

import { GridStyled } from '@/components/spinner.styled'
import { Center } from '@/components/styled'

type SpinnerProps = {
  height?: number
  offset?: number
  overlay?: boolean
  page?: boolean
}
const Spinner = ({ height = 250, offset = 0, overlay = false, page = false }: SpinnerProps) => {
  if (page) {
    return (
      <Center>
        <Spinner />
      </Center>
    )
  }
  return (
    <GridStyled $height={height} $offset={offset} $overlay={overlay} container>
      <CircularProgress />
    </GridStyled>
  )
}

export { Spinner }
