import { memo, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import ContentCopy from '@mui/icons-material/ContentCopy'
import EditIcon from '@mui/icons-material/Edit'
import { Tooltip } from '@mui/material'
import { GridActionsCellItem, type GridRowParams } from '@mui/x-data-grid-pro'
import { isEmpty } from 'lodash-es'
import { useDispatch, useSelector } from 'react-redux'

import { Table } from '@/components'
import { enhanceColumnGroupingModel } from '@/components/table/enhance-column-grouping-model'
import { enhanceColumns } from '@/components/table/enhance-columns'
import { CompanyTypes } from '@/constants'
import {
  useGetBusinessUnitByIdQuery,
  useGetShipmentsQuery,
} from '@/features/shipments/shipments.api.generated'
import { useTitle } from '@/routing/pages/layout-page.context'
import { auth } from '@/store/api/auth'
import { changeTableState, equalTableState, selectTableState } from '@/store/slices/table.slice'

const columns = (navigate: ReturnType<typeof useNavigate>) => {
  return [
    {
      field: 'actions',
      getActions: (params: GridRowParams) => {
        return [
          <GridActionsCellItem
            icon={
              <Tooltip title='View/Edit/Cancel'>
                <EditIcon />
              </Tooltip>
            }
            label='View/Edit/Cancel'
            onClick={() => {
              return navigate(`/shipments/${params.id as string}/edit`)
            }}
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title='Clone'>
                <ContentCopy />
              </Tooltip>
            }
            label='Clone'
            onClick={() => {
              return navigate(`/new-shipment?clone=${params.id as string}`)
            }}
          />,
        ]
      },
      headerName: 'Actions',
      minWidth: 50,
      type: 'actions',
    },
    {
      field: 'id',
      filterable: false,
      headerName: 'ID',
      minWidth: 120,
    },
    {
      field: 'shipper',
      headerName: 'Shipper',
      minWidth: 120,
      renderCell: Table.renderCompanyAvatar({
        id: 'shipper.id',
        name: 'shipper.name',
        type: CompanyTypes.SHIPPER,
      }),
      valueGetter: (cell) => cell.value.name,
    },
    {
      field: 'businessUnit.name',
      headerName: 'Business Unit',
      minWidth: 120,
    },
    {
      field: 'shipmentDetails.poNumber',
      headerName: 'Purchase Order (PO)',
      minWidth: 120,
    },
    {
      field: 'shipmentTender.executionPartnerShipmentId',
      headerName: 'Leaf Load Number',
      minWidth: 120,
    },
    {
      field: 'status.status',
      headerName: 'Status',
      minWidth: 120,
    },
    {
      field: 'shipmentDetails.transportationMode',
      headerName: 'Transporation Mode',
      minWidth: 50,
    },
    {
      field: 'shipmentDetails.stops[0].businessName',
      headerName: 'Origin',
      minWidth: 120,
    },
    {
      field: 'shipmentDetails.stops[1].businessName',
      headerName: 'Destination',
      minWidth: 120,
    },
    {
      field: 'shipmentDetails.stops[0].requestedDate',
      headerName: 'Requested Pickup Date',
      minWidth: 120,
    },
    {
      field: 'shipmentDetails.stops[1].requestedDate',
      headerName: 'Requested Delivery Date',
      minWidth: 120,
    },
    {
      field: 'createdAt',
      headerName: 'Tendered At',
      minWidth: 120,
    },
    {
      field: 'createdByCompany',
      headerName: 'Tendered By Company',
      minWidth: 120,
      renderCell: Table.renderCompanyAvatar({
        id: 'createdByCompany.id',
        name: 'createdByCompany.name',
        type: CompanyTypes.SHIPPER,
      }),
    },
  ]
}

const columnGroupingModel = [
  {
    children: [{ field: 'actions' }],
    groupId: 'Actions',
  },
  {
    children: [
      { field: 'id' },
      { field: 'shipper' },
      { field: 'businessUnit.name' },
      { field: 'shipmentDetails.poNumber' },
      { field: 'shipmentTender.executionPartnerShipmentId' },
      { field: 'status.status' },
      { field: 'shipmentDetails.transportationMode' },
      { field: 'shipmentDetails.stops[0].requestedDate' },
      { field: 'createdAt' },
      { field: 'createdByCompany' },
    ],
    groupId: 'General',
  },
]

const ShipmentOverview = () => {
  const navigate = useNavigate()
  const { setTitle } = useTitle()

  const savedState = useSelector(selectTableState('shipmentOverview'), equalTableState)
  const dispatch = useDispatch()

  const [businessUnitId, setBusinessUnitId] = useState<number | undefined>()
  const { data: shipmentsData, isLoading: shipmentsIsLoading } = useGetShipmentsQuery()
  const { data: businessUnitData, isLoading: businessUnitIsLoading } = useGetBusinessUnitByIdQuery({
    id: businessUnitId,
  })

  const enhancedColumnGroupingModel = useMemo(() => {
    return enhanceColumnGroupingModel(columnGroupingModel)
  }, [])
  const enhancedColumns = useMemo(() => {
    return enhanceColumns(columns(navigate))
  }, [navigate])

  useEffect(() => {
    setTitle('SHIPMENTS')
  }, [setTitle])

  useEffect(() => {
    if (!isEmpty(savedState)) {
      return
    }
    const initalState = {
      columns: {
        columnVisibilityModel: {
          'businessUnit.name': true,
          'createdAt': false,
          'createdByCompany': false,
          'id': false,
          'shipmentDetails.poNumber': true,
          'shipmentDetails.transportationMode': false,
          'shipmentTender.executionPartnerShipmentId': true,
          'shipper': true,
        },
      },
      filter: {
        filterModel: {
          items: [],
        },
      },
      sorting: {
        sortModel: [{ field: 'createdAt', sort: 'desc' }],
      },
    }
    if (!businessUnitId) {
      auth().then(({ user }) => {
        const userBusinessUnitId = parseInt(user!.business_unit_id, 10)
        if (!userBusinessUnitId) {
          dispatch(
            changeTableState({
              name: 'shipmentOverview',
              state: initalState,
            }),
          )
          return
        }
        setBusinessUnitId(userBusinessUnitId)
      })
      return
    }
    if (!businessUnitIsLoading && businessUnitData && businessUnitData.idmBusinessUnit) {
      dispatch(
        changeTableState({
          name: 'shipmentOverview',
          state: {
            ...initalState,
            filter: {
              filterModel: {
                items: [
                  {
                    field: 'shipper.name',
                    id: 1,
                    operator: 'equals',
                    value: businessUnitData.idmBusinessUnit[0].company.name,
                  },
                  {
                    field: 'businessUnit.name',
                    id: 2,
                    operator: 'equals',
                    value: businessUnitData.idmBusinessUnit[0].name,
                  },
                ],
              },
            },
          },
        }),
      )
    }
  }, [businessUnitId, businessUnitIsLoading, businessUnitData, savedState, dispatch])

  const rows = shipmentsIsLoading ? [] : shipmentsData?.tenderingShipment || []

  return (
    <Table.Component
      columnGroupingModel={enhancedColumnGroupingModel}
      columns={enhancedColumns}
      loading={isEmpty(savedState) || shipmentsIsLoading}
      name='shipmentOverview'
      rows={rows}
    />
  )
}

const ShipmentOverviewMemo = memo(ShipmentOverview)
export { ShipmentOverviewMemo as ShipmentOverview }
