import { combineReducers } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { hasuraApiReducer } from '@/store/api/hasura.api'
import { platformApiReducer } from '@/store/api/platform.api'
import { changelogReducer } from '@/store/slices/changelog.slice'
import { navigationReducer } from '@/store/slices/navigation.slice'
import { snackbarReducer } from '@/store/slices/snackbar.slice'
import { tableReducer } from '@/store/slices/table.slice'

const persistConfig = {
  blacklist: ['hasuraApi', 'platformApi'],
  key: 'root',
  storage,
}

// Add new global reducers here
const rootReducer = combineReducers({
  changelog: changelogReducer,
  hasuraApi: hasuraApiReducer,
  navigation: navigationReducer,
  platformApi: platformApiReducer,
  snackbar: snackbarReducer,
  table: tableReducer,
})

const reducer = persistReducer(persistConfig, rootReducer)

export { reducer }
