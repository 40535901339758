import { css } from '@mui/material/styles'

const globalStyles = css`
  @font-face {
    font-family: 'Avenir';
    font-weight: 300;
    src: url('/assets/fonts/avenir_300.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Avenir';
    font-weight: 500;
    src: url('/assets/fonts/avenir_500.woff2') format('woff2');
  }

  html,
  body,
  #root {
    height: 100vh;
    width: 100%;
    overflow-y: hidden;
    letter-spacing: 0;
  }
`

export { globalStyles }
