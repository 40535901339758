import { CssBaseline, GlobalStyles } from '@mui/material'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'

import { createCustomTheme } from '@/theme/create-custom-theme'
import { globalStyles } from '@/theme/global-styles'
import type { ChildrenProps } from '@/types'

import 'leaflet/dist/leaflet.css'

const CustomThemeProvider = ({ children }: ChildrenProps) => {
  const customTheme = createCustomTheme()
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={customTheme}>
        <CssBaseline />

        <GlobalStyles styles={globalStyles} />

        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export { CustomThemeProvider }
