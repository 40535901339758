import { memo } from 'react'

import { Link } from '@mui/material'
import type { GridRenderCellParams } from '@mui/x-data-grid-pro'
import { get } from 'lodash-es'

import { CompanyAvatar } from '@/components/company-avatar'

type CompanyAvatarProps = {
  id: string
  name: string
  type: string
}

const CompanyAvatarLink = ({ id, name, type }: CompanyAvatarProps) => {
  return (
    <Link href={`/companies/${id}`} underline='none'>
      <CompanyAvatar id={id} isChip name={name} type={type} />
    </Link>
  )
}
const CompanyAvatarLinkMemo = memo(CompanyAvatarLink)

const renderCompanyAvatar = (props: CompanyAvatarProps) => {
  return ({ row }: GridRenderCellParams) => {
    const id = get(row, props.id)
    const name = get(row, props.name)
    const type = props.type.includes('.') ? get(row, props.type) : props.type

    if (!id) {
      return null
    }
    return <CompanyAvatarLinkMemo id={id} name={name} type={type} />
  }
}

export { renderCompanyAvatar }
