import type { GridColumnGroupingModel } from '@mui/x-data-grid-pro'

type EnhancedGridColumnGroupingModelProps = GridColumnGroupingModel & {
  freeReordering?: boolean
}
const enhanceColumnGroupingModel = (columnGroupingModel: EnhancedGridColumnGroupingModelProps) => {
  return columnGroupingModel.map((model) => {
    // Free reordering between groups
    return { ...model, freeReordering: true }
  })
}

export { enhanceColumnGroupingModel }
