import { alpha, css, styled } from '@mui/material/styles'
import { DataGridPro as DataGrid, gridClasses } from '@mui/x-data-grid-pro'

import { Sizes } from '@/constants'

const DataGridStyled = styled(DataGrid)`
  height: calc(100vh - ${Sizes.titleAdjustment});
  letter-spacing: 0;

  .MuiDataGrid-iconSeparator {
    color: gray;
  }

  .MuiDataGrid-columnHeader:focus {
    outline: unset;
  }

  .MuiDataGrid-columnHeader:focus-within {
    outline: unset;
  }

  .MuiDataGrid-columnHeader,
  .MuiDataGrid-cell {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }

  .MuiDataGrid-columnsContainer,
  .MuiDataGrid-cell {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  ${({ theme }) => {
    const color = theme.palette.primary.main

    return css`
      .${gridClasses.row}.odd, .${gridClasses.row}.even {
        &:hover {
          background-color: ${alpha(color, 0.3)};
        }

        &.Mui-selected {
          background-color: ${alpha(color, 0.5)};

          &:hover,
          &.Mui-hovered {
            background-color: ${alpha(color, 0.7)};
          }
        }
      }

      .${gridClasses.row}.even {
        background-color: rgba(0, 0, 0, 0.07);
      }
    `
  }}
`

export { DataGridStyled }
