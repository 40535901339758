import { Box } from '@mui/material'
import { css, styled } from '@mui/material/styles'

import { navigationColors } from '@/navigation/colors'
import type { DrawerStateStyledProps } from '@/navigation/types'

const FooterStyled = styled(Box)`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${navigationColors.border};
  height: 60px;
  margin-top: auto;
  background-color: ${({ theme }) => {
    return theme.palette.brand.dark
  }};
  box-shadow: 0 1px 5px rgb(0, 0, 0, 0.12);
  position: sticky;
  bottom: 0;

  .MuiSvgIcon-root {
    margin: ${({ theme }) => {
    return theme.spacing(1)
  }};
    cursor: pointer;
    vertical-align: middle;
  }

  .MuiIconButton-root {
    padding: 0;
  }
`

const ToggleDrawerStyled = styled(Box) <DrawerStateStyledProps>`
  display: flex;

  ${({ $isDrawerOpen, theme }) => {
    return !$isDrawerOpen
      ? css`
          margin: 0 auto;
        `
      : css`
          margin-left: auto;
          padding: ${theme.spacing(1)};
        `
  }};
`

export { FooterStyled, ToggleDrawerStyled }
