const toMoney = (value: string, digits: number = 2) => {
  if (!value) {
    return null
  }
  const v = Number(value)
  const formatter = Intl.NumberFormat('en-US', {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
    ...(Math.abs(v) < 1 && { minimumIntegerDigits: 1 }),
    style: 'currency',
    currency: 'USD',
  })
  const money = formatter.format(v)
  return money
}

const toBigMoney = (value: string) => {
  if (!value) {
    return null
  }
  let v = parseFloat(value)
  let minus = ''

  if (v < 0) {
    minus = '-'
    v = Math.abs(v)
  }

  if (v < 1e3) {
    return `${minus}$${v.toFixed(0)}`
  }

  if (v < 1e6) {
    const number = v / 1e3
    return `${minus}$${number.toFixed(0)}k`
  }

  if (v < 1e9) {
    const number = v / 1e6
    return `${minus}$${number.toFixed(0)}M`
  }

  if (v < 1e12) {
    const number = v / 1e9
    return `${minus}$${number.toFixed(0)}B`
  }

  const number = v / 1e12
  return `${minus}$${number.toFixed(0)}T`
}

export { toMoney, toBigMoney }
