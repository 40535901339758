import { ErrorPage } from '@/routing/pages/error-page'
import { LayoutPage } from '@/routing/pages/layout-page'
import { privateRoutes, publicRoutes, redirectRoutes } from '@/routing/routes'
import { createSentryBrowserRouter } from '@/sentry'
import { auth } from '@/store/api/auth'
import type { AuthReturn } from '@/types'

type RootLoaderDataType = AuthReturn
const router = createSentryBrowserRouter([
  ...publicRoutes,
  {
    children: [
      {
        Component: LayoutPage,
        ErrorBoundary: ErrorPage,
        children: [...privateRoutes, ...redirectRoutes],
      },
    ],
    id: 'root',
    loader: async () => {
      // Accessible with useRouteLoaderData('root')
      const { isRedirectToLogin, token, user } = await auth()
      return { isRedirectToLogin, token, user }
    },
    path: '/',
  },
])

export { router }
export type { RootLoaderDataType }
