import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

import { navigationColors } from '@/navigation/colors'

const DrawerHeaderStyled = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid ${navigationColors.border};
  height: 60px;
  position: sticky;
  top: 0;
  z-index: 10;
  flex: 0 0 60px;
  background-color: ${({ theme }) => {
    return theme.palette.brand.dark
  }};
  gap: ${({ theme }) => {
    return `calc(${theme.spacing(1)} / 2)`
  }};

  img {
    height: 25px;
    margin: ${({ theme }) => {
    return theme.spacing(2)
  }};
  }

  h1 {
    color: #ffffff;
    overflow: hidden;
    letter-spacing: 0.1em;
  }

  h1:after {
    content: '．';
    color: ${({ theme }) => {
    return `${theme.palette.brand.light}`
  }};
  }
`

const DrawerHeaderToggleStyled = styled(Box)`
  margin-left: 3.3rem;
  overflow: hidden;
`

export { DrawerHeaderStyled, DrawerHeaderToggleStyled }
