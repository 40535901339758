import { Box, styled } from '@mui/material'

const FooterStyled = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${({ theme }) => {
    return `${theme.spacing(2)}`
  }};
  position: fixed;
  bottom: 0;
  height: 52px;
  width: 100%;
  padding: ${({ theme }) => {
    return `${theme.spacing(2)}`
  }};
  border-top: ${({ theme }) => {
    return `1px solid ${theme.palette.divider}`
  }};
`

export { FooterStyled }
