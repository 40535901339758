import { Typography, type TypographyProps } from '@mui/material'

const Body1 = ({ children, ...rest }: TypographyProps) => {
  return (
    <Typography {...rest} variant='body1'>
      {children}
    </Typography>
  )
}

export { Body1 }
