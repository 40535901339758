import { CompanyTypes } from '@/constants/company-types'

const CompanyColors = {
  [CompanyTypes.CARRIER]: {
    backgroundColor: '#e29c4a',
    color: 'rgba(0, 0, 0, 0, 0.87)',
  },
  [CompanyTypes.SHIPPER]: {
    backgroundColor: '#4a90e2',
    color: 'rgba(0, 0, 0, 0, 0.87)',
  },
}

export { CompanyColors }
