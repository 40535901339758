import { createTheme } from '@mui/material/styles'
import { merge } from 'lodash-es'

import { createPalette } from '@/theme/create-palette'
import { createTypography } from '@/theme/create-typography'
import { shadows } from '@/theme/shadows'

const createCustomTheme = () => {
  const palette = createPalette()
  const typography = createTypography()
  const theme = merge({
    palette,
    shadows,
    typography,
  })
  return createTheme(theme)
}

export { createCustomTheme }
