/**
 * Reduces object of class names to a string depending on the boolean value
 *
 * Example:
 * const foo = {foo: true, bar: true, baz: false}
 * const classNames = classes(foo)
 * classNames === 'foo bar'
 */
const merge = (classNames: { [key: string]: boolean }) => {
  return Object.entries(classNames)
    .reduce((acc, [key, value]) => {
      if (value) {
        return `${acc} ${key}`
      }
      return acc
    }, '')
    .trim()
}

export { merge }
