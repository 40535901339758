import { useState } from 'react'
import { Outlet, useNavigation, useRouteLoaderData } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'

import { ChangelogDialog, Snackbar } from '@/components'
import { changelogs } from '@/features'
import { Navigation } from '@/navigation'
import type { LayoutContext } from '@/routing/pages/layout-page.context'
import { ContentStyled, TitleStyled, WrapperStyled } from '@/routing/pages/layout-page.styled'
import { LoadingPage } from '@/routing/pages/loading-page'
import type { RootLoaderDataType } from '@/routing/router'
import { selectShouldOpenChangelog, setShouldOpenChangelog } from '@/store/slices/changelog.slice'

const LayoutPage = () => {
  const { isRedirectToLogin } = useRouteLoaderData('root') as RootLoaderDataType
  const navigation = useNavigation()
  const [title, setTitle] = useState<string>('')

  const shouldOpenChangelog = useSelector(selectShouldOpenChangelog)
  const dispatch = useDispatch()

  if (isRedirectToLogin) {
    return <LoadingPage />
  }
  const toggleChangelog = () => {
    dispatch(setShouldOpenChangelog(!shouldOpenChangelog))
  }
  const isLoading = navigation.state === 'loading'

  return (
    <WrapperStyled>
      <Navigation toggleChangelog={toggleChangelog} />

      <ChangelogDialog
        changelogs={changelogs}
        onClose={toggleChangelog}
        open={shouldOpenChangelog}
      />

      <ContentStyled>
        <TitleStyled>{title}</TitleStyled>

        {isLoading ? (
          <LoadingPage />
        ) : (
          <Outlet context={{ setTitle, title } satisfies LayoutContext} />
        )}
      </ContentStyled>

      <Snackbar />
    </WrapperStyled>
  )
}

export { LayoutPage }
