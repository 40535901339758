import { Typography, styled } from '@mui/material'

const FormGroupWrapper = styled('div')`
  padding-bottom: 20px;
`

const FormGroupTitle = styled(Typography)`
  margin-bottom: 15px;
`

export { FormGroupTitle, FormGroupWrapper }
