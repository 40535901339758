import { createApi } from '@reduxjs/toolkit/query/react'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'

import { prepareHeaders } from '@/store/api/prepare-headers'

const hasuraApi = createApi({
  baseQuery: graphqlRequestBaseQuery({
    prepareHeaders,
    url: 'https://leaf-api-prod.hasura.app/v1/graphql',
  }),
  endpoints: () => {
    return {}
  },
  reducerPath: 'hasuraApi',
})

const hasuraApiReducer = hasuraApi.reducer
const hasuraApiMiddleware = hasuraApi.middleware
export { hasuraApi, hasuraApiMiddleware, hasuraApiReducer }
