import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import { GridToolbarContainer } from '@mui/x-data-grid-pro'

const GridToolbarContainerStyled = styled(GridToolbarContainer)`
  justify-content: space-between;
`

const ButtonStyled = styled(Button)`
  padding: 4px 5px 4px 5px;
  font-size: 0.8125rem;
`

export { ButtonStyled, GridToolbarContainerStyled }
