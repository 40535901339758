import { Alert, IconButton, Snackbar as MuiSnackbar } from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'

import { removeSnackbar, selectSnackbars } from '@/store/slices/snackbar.slice'
import { memo } from 'react'

const Snackbar = () => {
  const snackbars = useSelector(selectSnackbars)
  const dispatch = useDispatch()

  return Object.entries(snackbars).map(([k, v]) => {
    const onClose = () => {
      dispatch(removeSnackbar(k))
    }
    return (
      <MuiSnackbar key={k} autoHideDuration={v.autoHideDuration} onClose={onClose} open>
        <Alert
          severity={v.severity || 'success'}
          action={
            <IconButton size='small' color='inherit' onClick={onClose}>
              <CloseIcon fontSize='small' />
            </IconButton>
          }
        >
          {v.message}
        </Alert>
      </MuiSnackbar>
    )
  })
}

const SnackbarMemo = memo(Snackbar)
export { SnackbarMemo as Snackbar }
