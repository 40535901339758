import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { prepareHeaders } from '@/store/api/prepare-headers'

const platformApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_API}`,
    prepareHeaders,
  }),
  endpoints: () => {
    return {}
  },
  reducerPath: 'platformApi',
})

const platformApiReducer = platformApi.reducer
const platformApiMiddleware = platformApi.middleware
export { platformApi, platformApiMiddleware, platformApiReducer }
