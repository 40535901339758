type StatesKey = keyof typeof States

const States = {
  AK: {
    division: 'West',
    lat: 61.370716,
    lng: -152.404419,
    text: 'AK - Alaska',
  },
  AL: {
    division: 'Southeast',
    lat: 32.806671,
    lng: -86.79113,
    text: 'AL - Alabama',
  },
  AR: {
    division: 'Southeast',
    lat: 34.969704,
    lng: -92.373123,
    text: 'AR - Arkansas',
  },
  AZ: {
    division: 'Southwest',
    lat: 33.729759,
    lng: -111.431221,
    text: 'AZ - Arizona',
  },
  CA: {
    division: 'West',
    lat: 36.116203,
    lng: -119.681564,
    text: 'CA - California',
  },
  CO: {
    division: 'West',
    lat: 39.059811,
    lng: -105.311104,
    text: 'CO - Colorado',
  },
  CT: {
    division: 'Northeast',
    lat: 41.597782,
    lng: -72.755371,
    text: 'CT - Connecticut',
  },
  DC: {
    division: 'West',
    lat: 38.897438,
    lng: -77.026817,
    text: 'DC - District Of Columbia',
  },
  DE: {
    division: 'Northeast',
    lat: 39.318523,
    lng: -75.507141,
    text: 'DE - Delaware',
  },
  FL: {
    division: 'Southeast',
    lat: 27.766279,
    lng: -81.686783,
    text: 'FL - Florida',
  },
  GA: {
    division: 'Southeast',
    lat: 33.040619,
    lng: -83.643074,
    text: 'GA - Georgia',
  },
  HI: {
    division: 'West',
    lat: 21.094318,
    lng: -157.498337,
    text: 'HI - Hawaii',
  },
  IA: {
    division: 'Midwest',
    lat: 42.011539,
    lng: -93.210526,
    text: 'IA - Iowa',
  },
  ID: {
    division: 'West',
    lat: 44.240459,
    lng: -114.478828,
    text: 'ID - Idaho',
  },
  IL: {
    division: 'Midwest',
    lat: 40.349457,
    lng: -88.986137,
    text: 'IL - Illinois',
  },
  IN: {
    division: 'Midwest',
    lat: 39.849426,
    lng: -86.258278,
    text: 'IN - Indiana',
  },
  KS: {
    division: 'Midwest',
    lat: 38.5266,
    lng: -96.726486,
    text: 'KS - Kansas',
  },
  KY: {
    division: 'Southeast',
    lat: 37.66814,
    lng: -84.670067,
    text: 'KY - Kentucky',
  },
  LA: {
    division: 'Southeast',
    lat: 31.169546,
    lng: -91.867805,
    text: 'LA - Louisiana',
  },
  MA: {
    division: 'Northeast',
    lat: 42.230171,
    lng: -71.530106,
    text: 'MA - Massachusetts',
  },
  MD: {
    division: 'Northeast',
    lat: 39.063946,
    lng: -76.802101,
    text: 'MD - Maryland',
  },
  ME: {
    division: 'Northeast',
    lat: 44.693947,
    lng: -69.381927,
    text: 'ME - Maine',
  },
  MI: {
    division: 'Midwest',
    lat: 43.326618,
    lng: -84.536095,
    text: 'MI - Michigan',
  },
  MN: {
    division: 'Midwest',
    lat: 45.694454,
    lng: -93.900192,
    text: 'MN - Minnesota',
  },
  MO: {
    division: 'Midwest',
    lat: 38.456085,
    lng: -92.288368,
    text: 'MO - Missouri',
  },
  MS: {
    division: 'Southeast',
    lat: 32.741646,
    lng: -89.678696,
    text: 'MS - Mississippi',
  },
  MT: {
    division: 'West',
    lat: 46.921925,
    lng: -110.454353,
    text: 'MT - Montana',
  },
  NC: {
    division: 'Southeast',
    lat: 35.630066,
    lng: -79.806419,
    text: 'NC - North Carolina',
  },
  ND: {
    division: 'Midwest',
    lat: 47.528912,
    lng: -99.784012,
    text: 'ND - North Dakota',
  },
  NE: {
    division: 'Midwest',
    lat: 41.12537,
    lng: -98.268082,
    text: 'NE - Nebraska',
  },
  NH: {
    division: 'Northeast',
    lat: 43.452492,
    lng: -71.563896,
    text: 'NH - New Hampshire',
  },
  NJ: {
    division: 'Northeast',
    lat: 40.298904,
    lng: -74.521011,
    text: 'NJ - New Jersey',
  },
  NM: {
    division: 'Southwest',
    lat: 34.840515,
    lng: -106.248482,
    text: 'NM - New Mexico',
  },
  NV: {
    division: 'West',
    lat: 38.313515,
    lng: -117.055374,
    text: 'NV - Nevada',
  },
  NY: {
    division: 'Northeast',
    lat: 42.165726,
    lng: -74.948051,
    text: 'NY - New York',
  },
  OH: {
    division: 'Midwest',
    lat: 40.388783,
    lng: -82.764915,
    text: 'OH - Ohio',
  },
  OK: {
    division: 'Southwest',
    lat: 35.565342,
    lng: -96.928917,
    text: 'OK - Oklahoma',
  },
  OR: {
    division: 'West',
    lat: 44.572021,
    lng: -122.070938,
    text: 'OR - Oregon',
  },
  PA: {
    division: 'Northeast',
    lat: 40.590752,
    lng: -77.209755,
    text: 'PA - Pennsylvania',
  },
  RI: {
    division: 'Northeast',
    lat: 41.680893,
    lng: -71.51178,
    text: 'RI - Rhode Island',
  },
  SC: {
    division: 'Southeast',
    lat: 33.856892,
    lng: -80.945007,
    text: 'SC - South Carolina',
  },
  SD: {
    division: 'Midwest',
    lat: 44.299782,
    lng: -99.438828,
    text: 'SD - South Dakota',
  },
  TN: {
    division: 'Southeast',
    lat: 35.747845,
    lng: -86.692345,
    text: 'TN - Tennessee',
  },
  TX: {
    division: 'Southwest',
    lat: 31.054487,
    lng: -97.563461,
    text: 'TX - Texas',
  },
  UT: {
    division: 'West',
    lat: 40.150032,
    lng: -111.862434,
    text: 'UT - Utah',
  },
  VA: {
    division: 'Southeast',
    lat: 37.769337,
    lng: -78.169968,
    text: 'VA - Virginia',
  },
  VT: {
    division: 'Northeast',
    lat: 44.045876,
    lng: -72.710686,
    text: 'VT - Vermont',
  },
  WA: {
    division: 'West',
    lat: 47.400902,
    lng: -121.490494,
    text: 'WA - Washington',
  },
  WI: {
    division: 'Midwest',
    lat: 44.268543,
    lng: -89.616508,
    text: 'WI - Wisconsin',
  },
  WV: {
    division: 'Southeast',
    lat: 38.491226,
    lng: -80.954453,
    text: 'WV - West Virginia',
  },
  WY: {
    division: 'West',
    lat: 42.755966,
    lng: -107.30249,
    text: 'WY - Wyoming',
  },
}

const StatesSelect = Object.keys(States).map((key) => ({
  id: key,
  label: States[key as keyof typeof States].text,
}))

export { States, StatesSelect }
export type { StatesKey }
