import { useEffect } from 'react'
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

import * as Sentry from '@sentry/react'

const init = () => {
  if (import.meta.env.DEV) {
    return
  }
  return Sentry.init({
    dsn: 'https://8e6382837f2811d9caecffa7fe58457b@o349867.ingest.sentry.io/4506189289357312',
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
        tracePropagationTargets: ['localhost', /^https:\/\/api\.leaflogistics\.com/],
      }),
      new Sentry.Replay(),
    ],
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    tracesSampleRate: 1.0,
  })
}

const createSentryBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)

export { createSentryBrowserRouter, init }
