import { useOutletContext } from 'react-router-dom'

type LayoutContext = {
  setTitle: (title: string) => void
  title: string
}

function useTitle() {
  return useOutletContext<LayoutContext>()
}

export { type LayoutContext, useTitle }
