import { memo } from 'react'

import { Powerlane } from '@/components/icons/powerlane'
import { LaneWrapperStyled, RowWrapperStyled } from '@/components/table/render-route.styled'
import { Body2 } from '@/components/text'
import type { Lane } from '@/types'

type RenderRouteLaneRowProps = Lane & {
  singleLeg: boolean
}
const RenderRouteLaneRow = ({
  destinationName,
  originName,
  singleLeg,
}: RenderRouteLaneRowProps) => {
  return (
    <LaneWrapperStyled $isSingleRow={singleLeg}>
      <RowWrapperStyled>
        <Body2 noWrap>{originName}</Body2>

        <Powerlane />

        <Body2 noWrap>{destinationName}</Body2>
      </RowWrapperStyled>
    </LaneWrapperStyled>
  )
}

const RenderRouteLaneRowMemo = memo(RenderRouteLaneRow, (prevProps, nextProps) => {
  return prevProps?.id === nextProps?.id
})

export { RenderRouteLaneRowMemo as RenderRouteLaneRow }
