import { memo, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { DrawerStyled } from '@/navigation/drawer.styled'
import { DrawerFooter } from '@/navigation/drawer-footer'
import { DrawerHeader } from '@/navigation/drawer-header'
import { DrawerMenu } from '@/navigation/drawer-menu'
import type { MenuItem } from '@/navigation/types'
import { selectIsDrawerOpen, setIsDrawerOpen } from '@/store/slices/navigation.slice'

type DrawerProps = {
  items: Array<MenuItem>
  title: string
}
const Drawer = ({ items, title }: DrawerProps) => {
  const [allMenusOpen, setAllMenusOpen] = useState(false)

  const isDrawerOpen = useSelector(selectIsDrawerOpen)

  const dispatch = useDispatch()
  const changeDrawerState = () => {
    return dispatch(setIsDrawerOpen(!isDrawerOpen))
  }

  const toggleDrawer = () => {
    changeDrawerState()
    setTimeout(() => {
      return window.dispatchEvent(new Event('resize'))
    }, 250)
  }

  const toggleAllMenus = () => {
    setAllMenusOpen(!allMenusOpen)
  }

  return (
    <DrawerStyled $isDrawerOpen={isDrawerOpen} variant='permanent'>
      <DrawerHeader allMenusOpen={allMenusOpen} title={title} toggleAllMenus={toggleAllMenus} />

      <DrawerMenu allMenusOpen={allMenusOpen} isDrawerOpen={isDrawerOpen} items={items} />

      <DrawerFooter isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
    </DrawerStyled>
  )
}

const Memo = memo(Drawer)
export { Memo as Drawer }
