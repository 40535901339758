import { merge } from 'lodash-es'

const fontFamily = '"Avenir", sans-serif'
const defaultHtmlFontSize = 16
const uppercase = {
  textTransform: 'uppercase',
}

const pxToRem = (fontSize: number, htmlFontSize = defaultHtmlFontSize) => {
  return `${(1 / htmlFontSize) * fontSize}rem}`
}

const buildVariant = (
  fontWeight: number,
  fontSize: number,
  lineHeight: number,
  letterSpacing: number,
  casing?: typeof uppercase,
) => {
  return {
    fontFamily,
    fontSize: pxToRem(fontSize),
    fontWeight,
    letterSpacing: `${letterSpacing}rem`,
    lineHeight: `${lineHeight}%`,
    ...casing,
  }
}

const createTypography = () => {
  const fontSize = 18

  const fontWeightLight = 300
  const fontWeightMedium = 500
  const variants = {
    body1: buildVariant(fontWeightLight, 14, 130, 0),
    body2: buildVariant(fontWeightLight, 13, 130, 0),
    button: buildVariant(fontWeightMedium, 12, 130, 0.004, uppercase),
    caption: buildVariant(fontWeightMedium, 13, 130, 0.0015),
    h1: buildVariant(fontWeightMedium, 19, 130, -0.015),
    h2: buildVariant(fontWeightMedium, 18, 130, -0.005),
    h3: buildVariant(fontWeightMedium, 17, 130, 0),
    h4: buildVariant(fontWeightMedium, 16, 130, 0.0025),
    h5: buildVariant(fontWeightMedium, 15, 130, 0),
    h6: buildVariant(fontWeightMedium, 14, 130, 0.0015),
    overline: buildVariant(fontWeightMedium, 10, 130, 0.0015, uppercase),
    subtitle1: buildVariant(fontWeightLight, 18, 130, 0.0015),
    subtitle2: buildVariant(fontWeightLight, 16, 130, 0.1),
  }
  return merge(
    {
      fontFamily,
      fontSize,
      fontWeightLight,
      fontWeightMedium,
      htmlFontSize: defaultHtmlFontSize,
      pxToRem,
      ...variants,
    },
    {
      clone: false,
    },
  )
}

export { createTypography, uppercase }
