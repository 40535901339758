import { Navigate, type RouteObject } from 'react-router-dom'

const DEFAULT_ROUTE = '/shipments'

const publicRoutes: never[] = []

// Redirects for renamed routes, delete after 3 months
const redirectRoutes: never[] = []

const privateRoutes: RouteObject[] = [
  { element: <Navigate to={DEFAULT_ROUTE} />, path: '/' },
  {
    lazy: async () => {
      const { ShipmentOverview } = await import('@/features')
      return { Component: ShipmentOverview }
    },
    path: '/shipments',
  },
  {
    lazy: async () => {
      const { ShipmentEdit } = await import('@/features')
      return { Component: ShipmentEdit }
    },
    path: '/shipments/:shipmentId/edit',
  },
  {
    lazy: async () => {
      const { ShipmentCreate } = await import('@/features')
      return { Component: ShipmentCreate }
    },
    path: '/new-shipment',
  },
]

export { privateRoutes, publicRoutes, redirectRoutes }
