import { styled } from '@mui/material/styles'

const LaneWrapperStyled = styled('div') <{ $isSingleRow: boolean }>`
  margin-bottom: ${({ $isSingleRow, theme }) => {
    return `${!$isSingleRow ? theme.spacing(1) : 0}`
  }};

  .MuiTypography-body2 {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 220px;
    white-space: nowrap;
    font-weight: 500;
  }
`

const RowWrapperStyled = styled('div')`
  display: grid;
  grid-template-columns: 220px 50px 220px;
  grid-gap: 10px;
  align-items: center;

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    height: 1.2em;
    white-space: nowrap;
  }

  svg {
    vertical-align: middle;
  }
`

export { LaneWrapperStyled, RowWrapperStyled }
