import { ListItemButton } from '@mui/material'
import { css, styled } from '@mui/material/styles'

import { Styled } from '@/components'
import type { DrawerStateStyledProps } from '@/navigation/types'

const NestedItemStyled = styled(Styled.Link) <DrawerStateStyledProps>`
  background-color: #132f48;

  padding-left: ${({ $isDrawerOpen }) => {
    return $isDrawerOpen ? '3.2rem' : '0rem'
  }};
`

const NestedItemParentStyled = styled(ListItemButton) <
  { $isActive: boolean } & DrawerStateStyledProps
>`
  ${({ $isActive, theme }) => {
    const color = '#ffffff'
    const backgroundColor = theme.palette.secondary.dark

    if ($isActive) {
      return css`
        background-color: ${backgroundColor};
        color: ${color};

        &&& svg {
          color: ${color};
        }
      `
    }
    return ''
  }}
`

export { NestedItemParentStyled, NestedItemStyled }
