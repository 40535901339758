import type { CurriedGetDefaultMiddleware } from '@reduxjs/toolkit/dist/getDefaultMiddleware'
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist'

import { hasuraApiMiddleware } from '@/store/api/hasura.api'
import { platformApiMiddleware } from '@/store/api/platform.api'

const middleware = (getDefaultMiddleware: CurriedGetDefaultMiddleware) => {
  return getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  })
    .concat(hasuraApiMiddleware)
    .concat(platformApiMiddleware)
}

export { middleware }
