import { createSlice } from '@reduxjs/toolkit'
import type { Dispatch, PayloadAction } from '@reduxjs/toolkit'
import { v4 as uuid } from 'uuid'

import type { RootState } from '@/store'

type SeverityType = 'error' | 'info' | 'success' | 'warning'

type SnackbarType = {
  autoHideDuration?: null | number
  message: string
  severity?: SeverityType
}
type StackType = {
  [key: string]: SnackbarType
}
const initialState = {
  stack: {} as StackType,
}

const snackbarSlice = createSlice({
  initialState,
  name: 'snackbar',
  reducers: {
    addSnackbar: (state, action: PayloadAction<SnackbarType>) => {
      state.stack[uuid()] = {
        autoHideDuration: 3000,
        severity: 'info',
        ...action.payload,
      }
    },
    removeSnackbar: (state, action) => {
      delete state.stack[action.payload]
    },
  },
})

const selectSnackbars = (state: RootState): StackType => {
  return state.snackbar.stack
}

const { addSnackbar, removeSnackbar } = snackbarSlice.actions
const { reducer: snackbarReducer } = snackbarSlice

const dispatchSnackbar = (dispatch: Dispatch, payload: SnackbarType) => {
  dispatch(addSnackbar(payload))
}

export { dispatchSnackbar, removeSnackbar, selectSnackbars, snackbarReducer }
