import { Drawer as MuiDrawer } from '@mui/material'
import { css, styled } from '@mui/material/styles'
import type { Theme } from '@mui/material/styles'

import { navigationColors } from '@/navigation/colors'
import type { DrawerStateStyledProps } from '@/navigation/types'

const color = 'rgba(255, 255, 255, 0.8)'
const drawerWidthOpen = 255
const drawerWidthClosed = 60

type GetTransitionProps = DrawerStateStyledProps & {
  theme: Theme
}
const getTransition = ({ $isDrawerOpen, theme }: GetTransitionProps) => {
  if ($isDrawerOpen) {
    return css`
      width: ${drawerWidthOpen}px;
      transition: ${theme.transitions.create('width', {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.sharp,
    })};
    `
  }
  return css`
    width: ${drawerWidthClosed}px;
    overflow: visible;
    transition: ${theme.transitions.create('width', {
    duration: theme.transitions.duration.leavingScreen,
    easing: theme.transitions.easing.sharp,
  })};
  `
}

const DrawerStyled = styled(MuiDrawer) <DrawerStateStyledProps>`
  flex-shrink: 0;
  white-space: nowrap;

  ${getTransition}

  .MuiSvgIcon-root {
    font-size: 1.75rem;
  }

  .MuiListItemIcon-root {
    min-width: 35px;
  }

  & > div {
    overflow-y: overlay;
  }

  // NavLink
  .active {
    color: #ffffff;
    background-color: ${navigationColors.section};

    .MuiSvgIcon-root {
      color: #ffffff;
    }
  }

  .MuiListItemButton-root:hover {
    background-color: ${({ theme }) => {
    return `${theme.palette.primary.main}`
  }};
    color: ${color};
    position: relative;

    .MuiSvgIcon-root {
      color: ${color};
    }
  }

  .MuiList-padding {
    padding: 0;
  }

  .MuiDrawer-paper {
    background-color: ${({ theme }) => {
    return `${theme.palette.secondary.main}`
  }};
    color: ${navigationColors.text};
    overflow-x: hidden;

    .MuiSvgIcon-root {
      color: ${navigationColors.text};
    }

    .MuiDivider-root {
      background-color: rgba(255, 255, 255, 0.1);
    }

    ${getTransition}
  }

  ${({ $isDrawerOpen }) => {
    if ($isDrawerOpen) {
      return css`
        width: ${drawerWidthOpen}px;
      `
    }
    return css`
      width: ${drawerWidthClosed}px;

      .MuiList-padding {
        position: relative;

        .MuiListItemText-root,
        .MuiTypography-root,
        .MuiCollapse-root {
          visibility: hidden;
          opacity: 0;
        }

        &:hover {
          width: ${drawerWidthOpen}px;

          .drawer-nested-item-parent:first-of-type {
            background-color: ${navigationColors.section} !important;
            color: ${color} !important;

            .MuiSvgIcon-root {
              color: ${color} !important;
            }
          }

          .MuiListItemText-root {
            padding-left: 1em;
          }
          .MuiCollapse-root {
            visibility: visible !important;
            height: auto !important;
            background-color: ${navigationColors.background};
            color: ${navigationColors.text};
            position: absolute;
            top: 100%;
            left: 59px;
            width: 196px;
            z-index: 10;
            border-bottom-right-radius: 4px;
          }

          .MuiListItemText-root,
          .MuiTypography-root,
          .MuiCollapse-root {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    `
  }}
`

export { DrawerStyled }
