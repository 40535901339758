/* eslint-disable */
import * as Types from '../../store/api/hasura.types.generated';

import { hasuraApi } from '@/store/api';
export type GetBusinessUnitByIdQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['bigint']['input']>;
}>;


export type GetBusinessUnitByIdQuery = { __typename?: 'query_root', idmBusinessUnit: Array<{ __typename?: 'IdmBusinessUnit', id: any, name: string, company: { __typename?: 'companies', id: any, name: string } }> };

export type GetShippersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetShippersQuery = { __typename?: 'query_root', tenderingCompanyCanTenderForCompany: Array<{ __typename?: 'TenderingCompanyCanTenderForCompany', shipper: { __typename?: 'companies', id: any, name: string } }> };

export type GetBusinessUnitsByShipperQueryVariables = Types.Exact<{
  companyId?: Types.InputMaybe<Types.Scalars['bigint']['input']>;
}>;


export type GetBusinessUnitsByShipperQuery = { __typename?: 'query_root', idmBusinessUnit: Array<{ __typename?: 'IdmBusinessUnit', id: any, name: string }> };

export type GetLocationsQueryVariables = Types.Exact<{
  name?: Types.InputMaybe<Types.Scalars['String']['input']>;
  street?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetLocationsQuery = { __typename?: 'query_root', executionLocation: Array<{ __typename?: 'ExecutionLocation', shipperLocationName?: string | null, shipperLocationReference?: string | null, businessName?: string | null, street?: string | null, city?: string | null, state?: string | null, zip?: string | null, country?: string | null, operatingHours?: string | null, appointmentRequirements?: string | null, contactName?: string | null, contactPhone?: string | null, contactEmail?: string | null }> };

export type GetShipmentsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetShipmentsQuery = { __typename?: 'query_root', tenderingShipment: Array<{ __typename?: 'TenderingShipment', id: any, shipperShipmentId: string, shipmentDetails: any, createdAt: any, updatedAt: any, shipper?: { __typename?: 'companies', id: any, name: string } | null, businessUnit?: { __typename?: 'IdmBusinessUnit', name: string } | null, shipmentTender?: { __typename?: 'shipmentTenders', executionPartnerShipmentId: string } | null, createdByUser: { __typename?: 'users', id: any, firstName?: string | null, lastName?: string | null }, updatedByUser: { __typename?: 'users', id: any, firstName?: string | null, lastName?: string | null }, createdByCompany: { __typename?: 'companies', id: any, name: string }, status?: { __typename?: 'TenderingVwShipmentStatus', status?: string | null } | null }> };

export type GetShipmentQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['uuid']['input']>;
}>;


export type GetShipmentQuery = { __typename?: 'query_root', tenderingShipment: Array<{ __typename?: 'TenderingShipment', shipperShipmentId: string, shipmentDetails: any, shipper?: { __typename?: 'companies', id: any } | null, status?: { __typename?: 'TenderingVwShipmentStatus', status?: string | null } | null }> };


export const GetBusinessUnitByIdDocument = `
    query GetBusinessUnitById($id: bigint) {
  idmBusinessUnit(where: {id: {_eq: $id}}) {
    id
    name
    company {
      id
      name
    }
  }
}
    `;
export const GetShippersDocument = `
    query GetShippers {
  tenderingCompanyCanTenderForCompany(distinctOn: canTenderForCompanyId) {
    shipper {
      id
      name
    }
  }
}
    `;
export const GetBusinessUnitsByShipperDocument = `
    query GetBusinessUnitsByShipper($companyId: bigint) {
  idmBusinessUnit(where: {companyId: {_eq: $companyId}}) {
    id
    name
  }
}
    `;
export const GetLocationsDocument = `
    query GetLocations($name: String, $street: String) {
  executionLocation(
    limit: 5
    where: {_and: {_or: [{shipperLocationName: {_ilike: $name}}, {shipperLocationReference: {_ilike: $name}}, {businessName: {_ilike: $name}}], street: {_ilike: $street}}}
  ) {
    shipperLocationName
    shipperLocationReference
    businessName
    street
    city
    state
    zip
    country
    operatingHours
    appointmentRequirements
    contactName
    contactPhone
    contactEmail
  }
}
    `;
export const GetShipmentsDocument = `
    query GetShipments {
  tenderingShipment(orderBy: {createdAt: DESC}) {
    id
    shipper {
      id
      name
    }
    businessUnit {
      name
    }
    shipperShipmentId
    shipmentDetails
    shipmentTender {
      executionPartnerShipmentId
    }
    createdByUser {
      id
      firstName
      lastName
    }
    updatedByUser {
      id
      firstName
      lastName
    }
    createdByCompany {
      id
      name
    }
    status {
      status
    }
    createdAt
    updatedAt
  }
}
    `;
export const GetShipmentDocument = `
    query GetShipment($id: uuid) {
  tenderingShipment(where: {id: {_eq: $id}}) {
    shipper {
      id
    }
    shipperShipmentId
    shipmentDetails
    status {
      status
    }
  }
}
    `;

const injectedRtkApi = hasuraApi.injectEndpoints({
  endpoints: (build) => ({
    GetBusinessUnitById: build.query<GetBusinessUnitByIdQuery, GetBusinessUnitByIdQueryVariables | void>({
      query: (variables) => ({ document: GetBusinessUnitByIdDocument, variables })
    }),
    GetShippers: build.query<GetShippersQuery, GetShippersQueryVariables | void>({
      query: (variables) => ({ document: GetShippersDocument, variables })
    }),
    GetBusinessUnitsByShipper: build.query<GetBusinessUnitsByShipperQuery, GetBusinessUnitsByShipperQueryVariables | void>({
      query: (variables) => ({ document: GetBusinessUnitsByShipperDocument, variables })
    }),
    GetLocations: build.query<GetLocationsQuery, GetLocationsQueryVariables | void>({
      query: (variables) => ({ document: GetLocationsDocument, variables })
    }),
    GetShipments: build.query<GetShipmentsQuery, GetShipmentsQueryVariables | void>({
      query: (variables) => ({ document: GetShipmentsDocument, variables })
    }),
    GetShipment: build.query<GetShipmentQuery, GetShipmentQueryVariables | void>({
      query: (variables) => ({ document: GetShipmentDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetBusinessUnitByIdQuery, useLazyGetBusinessUnitByIdQuery, useGetShippersQuery, useLazyGetShippersQuery, useGetBusinessUnitsByShipperQuery, useLazyGetBusinessUnitsByShipperQuery, useGetLocationsQuery, useLazyGetLocationsQuery, useGetShipmentsQuery, useLazyGetShipmentsQuery, useGetShipmentQuery, useLazyGetShipmentQuery } = injectedRtkApi;

