import { useState } from 'react'

import { MDXProvider } from '@mdx-js/react'
import CloseIcon from '@mui/icons-material/Close'
import { Button, Step, Stepper } from '@mui/material'
import type { MDXProps } from 'mdx/types'

import {
  DialogCloseStyled,
  DialogContentStyled,
  DialogStyled,
  StepperActionsStyled,
  StepperContentStyled,
} from '@/components/changelog-dialog.styled'

type ChangelogDialogProps = {
  changelogs: ((props: MDXProps) => JSX.Element)[]
  onClose: () => void
  open: boolean
}
const ChangelogDialog = ({ changelogs, onClose, open }: ChangelogDialogProps) => {
  const [activeChangelog, setActiveChangelog] = useState(0)

  const Changelog = changelogs[activeChangelog]

  const handleNext = () => {
    setActiveChangelog(activeChangelog - 1)
  }

  const handleBack = () => {
    setActiveChangelog(activeChangelog + 1)
  }

  const isNewest = activeChangelog === 0
  const isOldest = activeChangelog === changelogs.length - 1

  return (
    <DialogStyled onClose={onClose} open={open}>
      <DialogCloseStyled onClick={onClose}>
        <CloseIcon />
      </DialogCloseStyled>
      <DialogContentStyled>
        <Stepper activeStep={activeChangelog}>
          <Step>
            <StepperContentStyled>
              <MDXProvider>
                <Changelog />
              </MDXProvider>
            </StepperContentStyled>
          </Step>
        </Stepper>

        <StepperActionsStyled>
          <Button disabled={isOldest} onClick={handleBack}>
            Previous
          </Button>

          <Button disabled={isNewest} onClick={handleNext}>
            Next
          </Button>
        </StepperActionsStyled>
      </DialogContentStyled>
    </DialogStyled>
  )
}

export { ChangelogDialog }
