import { memo } from 'react'

import {
  Notes as ChangelogIcon,
  ExitToApp as LogoutIcon,
  AddCircle as NewShipmentIcon,
  LocalShipping as ShipmentsIcon,
} from '@mui/icons-material'

import { Drawer } from '@/navigation/drawer'
import { auth } from '@/store/api/auth'
import type { Noop } from '@/types'

const getItems = (toggleChangelog: Noop) => {
  return [
    {
      icon: ShipmentsIcon,
      text: 'shipments',
      to: '/shipments',
    },
    {
      icon: NewShipmentIcon,
      text: 'new shipment',
      to: '/new-shipment',
    },
    {
      icon: ChangelogIcon,
      onClick: toggleChangelog,
      text: 'changelog',
    },
    {
      icon: LogoutIcon,
      onClick: async () => {
        const { client } = await auth()
        const options = {
          logoutParams: {
            returnTo: window.location.origin,
          },
        }
        await client!.logout(options)
      },
      text: 'logout',
    },
  ]
}

const Navigation = ({
  // Default is noop since it's used in routing as well
  toggleChangelog = () => {},
}: {
  toggleChangelog?: Noop
}) => {
  const items = getItems(toggleChangelog)
  return <Drawer items={items} title='tender' />
}

const Memo = memo(Navigation)
export { Memo as Navigation }
