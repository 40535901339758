import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'

import * as FullStory from '@fullstory/browser'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'
import { LicenseInfo } from '@mui/x-license-pro'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

import { router } from '@/routing'
import * as Sentry from '@/sentry'
import { store } from '@/store'
import { CustomThemeProvider } from '@/theme'

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUIX_PRO_LICENSE_KEY!)

Sentry.init()
FullStory.init({ devMode: import.meta.env.DEV, orgId: 'o-1CJS2H-na1' })

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <CustomThemeProvider>
      <Provider store={store}>
        <PersistGate persistor={persistStore(store)}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <RouterProvider router={router} />
          </LocalizationProvider>
        </PersistGate>
      </Provider>
    </CustomThemeProvider>
  </StrictMode>,
)
