import { memo } from 'react'
import { NavLink } from 'react-router-dom'

import { ListItemButton, ListItemIcon } from '@mui/material'

import { Styled } from '@/components'
import { ItemTextStyled } from '@/navigation/item-text.styled'
import type { MenuItem } from '@/navigation/types'

const ActionItem = ({ icon: Icon, onClick, text }: MenuItem) => {
  return (
    <ListItemButton onClick={onClick}>
      <ListItemIcon>
        <Icon />
      </ListItemIcon>

      <ItemTextStyled primary={text} />
    </ListItemButton>
  )
}

const LinkItem = ({ icon: Icon, text, to }: MenuItem) => {
  return (
    <Styled.Link component={NavLink} to={to}>
      <ListItemIcon>
        <Icon />
      </ListItemIcon>

      <ItemTextStyled primary={text} />
    </Styled.Link>
  )
}

const Item = ({ item }: { item: MenuItem }) => {
  const text = item.text.toUpperCase()

  if (item.onClick) {
    return <ActionItem icon={item.icon} onClick={item.onClick} text={text} />
  }
  return <LinkItem icon={item.icon} text={text} to={item.to} />
}

const Memo = memo(Item)
export { Memo as Item }
