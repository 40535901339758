import type { GridColDef, GridValueGetterParams } from '@mui/x-data-grid-pro'
import { cloneDeep, get } from 'lodash-es'

import { toBigMoney, toMoney } from '@/components/table/utilities'

/**
 * Nested selectors for fields with dot separator { field: 'foo.bar.baz' }
 * Automatic coversion of percentages { type: 'percent' }, e.g. 0.3 = 30%
 * Automatic coversion of money { type: 'money' }, e.g. 0.3 = $0.30
 * Automatic coversion of big money values { type: 'bigMoney' }, e.g. 100000000 = $1M
 * Automatic conversion of dates and datetimes
 */
const enhanceColumns = (columns: GridColDef[]) => {
  return columns.map((column) => {
    const c = cloneDeep(column)

    // Make every column equal width
    c.flex = 1

    if (!c.valueGetter) {
      c.valueGetter = ({ row }: GridValueGetterParams) => {
        return get(row, column.field, null)
      }
    }

    // type === percent
    if (column?.type === 'percent') {
      c.type = 'number'
      c.valueGetter = ({ row }) => {
        const value = get(row, column.field, 0)
        return Number(value) * 100
      }
      c.valueFormatter = ({ value }) => {
        return `${value}%`
      }
    }

    // type === money
    if (column?.type === 'money') {
      c.type = 'number'
      c.valueGetter = ({ row }) => {
        const value = get(row, column.field)

        if (value) {
          return Number(value).toFixed(2)
        }
        return null
      }
      c.valueFormatter = ({ value }) => {
        return toMoney(value)
      }
    }

    // type === bigMoney
    if (column?.type === 'bigMoney') {
      c.type = 'number'
      c.valueGetter = ({ row }) => {
        const value = get(row, column.field)

        if (value) {
          return Number(value).toFixed(0)
        }
        return null
      }
      c.valueFormatter = ({ value }) => {
        return toBigMoney(value)
      }
    }

    // type === (date || dateTime)
    if (column.type && ['date', 'dateTime'].includes(column.type)) {
      c.valueGetter = ({ row }: GridValueGetterParams) => {
        const value = get(row, column.field, null)

        if (value) {
          return new Date(value)
        }
        return null
      }
    }

    return c
  })
}

export { enhanceColumns }
