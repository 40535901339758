import { styled } from '@mui/material/styles'
import { Box, Dialog, DialogContent, IconButton } from '@mui/material'

const DialogStyled = styled(Dialog)`
  min-height: 1024px;
`

const DialogCloseStyled = styled(IconButton)`
  position: absolute;
  right: 8px;
  top: 8px;
  color: ${({ theme }) => {
    return `${theme.palette.grey[500]}`
  }};
`

const DialogContentStyled = styled(DialogContent)`
  min-width: 256px;

  h1 {
    font-size: 1.2rem;
  }
  h2 {
    font-size: 1rem;
    margin-bottom: 0;
  }
  h3 {
    font-size: 0.9rem;
    margin-top: 0.25rem;
    margin-bottom: 0;
  }
  ul {
    margin-top: 0.25rem;
    padding-left: 1rem;
  }
  li {
    font-size: 0.9rem;
  }
`

const StepperContentStyled = styled(Box)`
  max-height: 256px;
  overflow-y: scroll;
`

const StepperActionsStyled = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 2em;
`

export {
  DialogStyled,
  DialogCloseStyled,
  DialogContentStyled,
  StepperContentStyled,
  StepperActionsStyled,
}
