import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { isEqual } from 'lodash-es'

import type { RootState } from '@/store'

type TableStateKeyType = keyof typeof initialState
type TableNameType = { name: TableStateKeyType }
type TableActionType = TableNameType & { state: object }

const initialState = {
  equipmentTypeOverview: {},
  fuelSurchargeScheduleOverview: {},
  lanePlanOverview: {},
  shipmentOverview: {},
}

const tableSlice = createSlice({
  initialState,
  name: 'table',
  reducers: {
    changeTableState: (state, action: PayloadAction<TableActionType>) => {
      state[action.payload.name] = action.payload.state
    },
    resetTableState: (state, action: PayloadAction<TableNameType>) => {
      state[action.payload.name] = initialState[action.payload.name]
    },
  },
})

const selectTableState = (name: keyof typeof initialState) => {
  return (state: RootState) => {
    return state.table[name]
  }
}

const equalTableState = (oldState: object, newState: object) => {
  return isEqual(oldState, newState)
}

const { changeTableState, resetTableState } = tableSlice.actions
const { reducer: tableReducer } = tableSlice

export { changeTableState, equalTableState, resetTableState, selectTableState, tableReducer }
export type { TableStateKeyType }
