import { memo } from 'react'

import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { IconButton } from '@mui/material'

import { Text } from '@/components'
import { DrawerHeaderStyled, DrawerHeaderToggleStyled } from '@/navigation/drawer-header.styled'
import type { Noop } from '@/types'

type DrawerHeaderProps = {
  allMenusOpen: boolean
  title: string
  toggleAllMenus: Noop
}
const DrawerHeader = ({ allMenusOpen, title, toggleAllMenus }: DrawerHeaderProps) => {
  return (
    <DrawerHeaderStyled>
      <img alt='Leaf' src='/assets/images/leaf_logo_light.svg' />

      <Text.H1>{title}</Text.H1>

      <DrawerHeaderToggleStyled>
        <IconButton onClick={toggleAllMenus}>
          {allMenusOpen ? <ArrowDropUp /> : <ArrowDropDown />}
        </IconButton>
      </DrawerHeaderToggleStyled>
    </DrawerHeaderStyled>
  )
}

const Memo = memo(DrawerHeader)
export { Memo as DrawerHeader }
