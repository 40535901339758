import { memo } from 'react'

import { List } from '@mui/material'

import { Item } from '@/navigation/item'
import { ItemNested } from '@/navigation/item-nested'
import type { MenuItem } from '@/navigation/types'

type DrawerMenuProps = {
  allMenusOpen: boolean
  isDrawerOpen: boolean
  items: Array<MenuItem>
}
const DrawerMenu = ({ allMenusOpen, isDrawerOpen, items }: DrawerMenuProps) => {
  return items.map((item) => {
    if (item.subs?.length) {
      return (
        <ItemNested
          allMenusOpen={allMenusOpen}
          isDrawerOpen={isDrawerOpen}
          item={item}
          key={item.text}
        />
      )
    }
    return (
      <List key={item.text}>
        <Item item={item} />
      </List>
    )
  })
}

const Memo = memo(DrawerMenu)
export { Memo as DrawerMenu }
