import { NavLink } from 'react-router-dom'

import { ListItemButton, styled } from '@mui/material'

// Workaround for TS + MUI + NavLink
// TODO: Rename to ListItemLinkStyled
const LinkStyled = styled(ListItemButton)<{
  component: typeof NavLink
  to?: string
}>``

export { LinkStyled }
