import { useCallback, useEffect } from 'react'

import {
  type GridColDef,
  type GridColumnGroupingModel,
  type GridRowClassNameParams,
  type GridValidRowModel,
  gridFilteredSortedRowIdsSelector,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import { useDispatch, useSelector } from 'react-redux'

import { CustomToolbar } from '@/components/table/render-custom-toolbar'
import { DataGridStyled } from '@/components/table/table.styled'
import { type ExportOptions, type ImportOptions } from '@/components/table/types'
import {
  type TableStateKeyType,
  changeTableState,
  equalTableState,
  resetTableState,
  selectTableState,
} from '@/store/slices/table.slice'

type TableProps = {
  columnGroupingModel: GridColumnGroupingModel
  columns: Array<GridColDef>
  exportOptions?: ExportOptions
  importOptions?: ImportOptions
  loading: boolean
  name: TableStateKeyType
  rows: GridValidRowModel[]
}

const Table = ({
  columnGroupingModel,
  columns,
  exportOptions = {},
  importOptions = {},
  loading,
  name,
  rows,
}: TableProps) => {
  const apiRef = useGridApiRef()

  const savedState = useSelector(selectTableState(name), equalTableState)
  const dispatch = useDispatch()

  const updateTableState = useCallback(() => {
    const state = apiRef.current.exportState()
    if (!equalTableState(savedState, state)) {
      dispatch(
        changeTableState({
          name,
          state,
        }),
      )
    }
  }, [savedState, apiRef, dispatch, name])

  useEffect(() => {
    if (savedState) {
      apiRef.current.restoreState(savedState)
    }
  }, [savedState, apiRef])

  useEffect(() => {
    return apiRef.current.subscribeEvent('columnHeaderDragEnd', () => {
      updateTableState()
    })
  }, [apiRef, updateTableState])

  // Saving table state on column visibility change
  useEffect(() => {
    return apiRef.current.subscribeEvent('columnVisibilityModelChange', () => {
      updateTableState()
    })
  }, [apiRef, updateTableState])

  // Saving table state on filter change
  const onFilterModelChange = useCallback(() => {
    updateTableState()
  }, [updateTableState])

  // Saving table state on column resizing change
  const onColumnWidthChange = useCallback(() => {
    updateTableState()
  }, [updateTableState])

  // Saving table state on column pinning change
  const onPinnedColumnsChange = useCallback(() => {
    updateTableState()
  }, [updateTableState])

  // Saving table state on pagination change
  const onPaginationModelChange = useCallback(() => {
    updateTableState()
  }, [updateTableState])

  // Saving table state on sort change
  const onSortModelChange = useCallback(() => {
    updateTableState()
  }, [updateTableState])

  const getRowClassName = useCallback((params: GridRowClassNameParams) => {
    return params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
  }, [])

  const buildExport = () => {
    const { onClick } = exportOptions

    if (onClick) {
      return () => {
        const rs = gridFilteredSortedRowIdsSelector(apiRef)
        return onClick(rs as string[])
      }
    }
    return () => {
      return apiRef.current.exportDataAsCsv(exportOptions)
    }
  }

  return (
    <DataGridStyled
      apiRef={apiRef}
      columnGroupingModel={columnGroupingModel}
      columns={columns}
      disableRowSelectionOnClick
      // https://github.com/mui/mui-x/issues/9286
      experimentalFeatures={{ columnGrouping: false }}
      getRowClassName={getRowClassName}
      initialState={{
        pagination: { paginationModel: { pageSize: 25 } },
        ...savedState,
      }}
      loading={loading}
      onColumnWidthChange={onColumnWidthChange}
      onFilterModelChange={onFilterModelChange}
      onPaginationModelChange={onPaginationModelChange}
      onPinnedColumnsChange={onPinnedColumnsChange}
      onSortModelChange={onSortModelChange}
      pageSizeOptions={[10, 25, 50, 75, 100]}
      pagination
      // TODO: LEAF-4930
      // https://github.com/mui/mui-x/issues/7041
      rows={rows}
      slotProps={{
        toolbar: {
          exportOptions: {
            // Use the built-in CSV download as default
            onClick: buildExport(),
          },
          importOptions,
          onReset: () => {
            return dispatch(resetTableState({ name }))
          },
          quickFilterProps: { debounceMs: 1000 },
          showQuickFilter: true,
        },
      }}
      slots={{
        toolbar: CustomToolbar,
      }}
      unstable_headerFilters
    />
  )
}

export { Table }
