import { type SyntheticEvent, memo, useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { Collapse, List, ListItemIcon } from '@mui/material'

import { NestedItemParentStyled, NestedItemStyled } from '@/navigation/item-nested.styled'
import { ItemTextStyled } from '@/navigation/item-text.styled'
import type { MenuItem, NestedMenuItem } from '@/navigation/types'
import { classes } from '@/utilities'

type NestedItemProps = {
  allMenusOpen: boolean
  isDrawerOpen: boolean
  item: MenuItem
}
const ItemNested = ({ allMenusOpen, isDrawerOpen, item }: NestedItemProps) => {
  const location = useLocation()
  const { pathname } = location
  const subs = item.subs!
  const [isNestedMenuOpen, setIsNestedMenuOpen] = useState(
    Boolean(
      subs.find((sub) => {
        return pathname === sub.to
      }),
    ),
  )

  useEffect(() => {
    setIsNestedMenuOpen(allMenusOpen)
  }, [allMenusOpen])

  const isActive = Boolean(
    subs.find((sub) => {
      return location.pathname.startsWith(sub.to)
    }),
  )

  const toggleNestedMenu = () => {
    setIsNestedMenuOpen(!isNestedMenuOpen)
  }

  const handleOpenCollapsedItem = () => {
    setIsNestedMenuOpen(true)
  }

  const renderArrowIcons = () => {
    if (isDrawerOpen) {
      if (isNestedMenuOpen) {
        return <ArrowDropUp />
      }
      return <ArrowDropDown />
    }
    return null
  }

  const handleNestedClick = (sub: NestedMenuItem) => {
    return (event: SyntheticEvent) => {
      if (sub.external) {
        event.preventDefault()
        window.open(sub.to, '_blank')
        return null
      }
      if (!isDrawerOpen) {
        return handleOpenCollapsedItem()
      }
      return null
    }
  }

  return (
    <List>
      <NestedItemParentStyled
        $isActive={isActive}
        $isDrawerOpen={isDrawerOpen}
        className={classes.merge({
          'drawer-nested-item-parent': !isDrawerOpen,
        })}
        onClick={toggleNestedMenu}
      >
        <ListItemIcon>
          <item.icon />
        </ListItemIcon>

        <ItemTextStyled primary={item.text.toUpperCase()} />
        {renderArrowIcons()}
      </NestedItemParentStyled>

      <Collapse in={isNestedMenuOpen && isDrawerOpen}>
        <List component='div' disablePadding>
          {subs.map((sub) => {
            return (
              <NestedItemStyled
                $isDrawerOpen={isDrawerOpen}
                component={NavLink}
                key={sub.to}
                onClick={handleNestedClick(sub)}
                to={sub.to}
              >
                <ItemTextStyled primary={sub.text} />
              </NestedItemStyled>
            )
          })}
        </List>
      </Collapse>
    </List>
  )
}

const Memo = memo(ItemNested)
export { Memo as ItemNested }
