import { memo } from 'react'

import { AvatarStyled, ChipStyled } from '@/components/company-avatar.styled'

type CompanyAvatarProps = {
  id: string | number
  type?: string
  name?: string
  isChip?: boolean
  size?: number
}
const CompanyAvatar = ({ id, type, name = '', isChip = false, size = 40 }: CompanyAvatarProps) => {
  const src = `https://assets.leaflogistics.com/logos/${id}_logo_image.png`
  const CustomAvatar = <AvatarStyled alt={name} src={src} $size={size} />

  if (isChip) {
    return <ChipStyled label={name} avatar={CustomAvatar} variant='outlined' $type={type} />
  }
  return CustomAvatar
}

const MemoizedCompanyAvatar = memo(CompanyAvatar)
export { MemoizedCompanyAvatar as CompanyAvatar }
