import { Spinner, Styled } from '@/components'

const LoadingPage = () => {
  return (
    <Styled.Center>
      <Spinner />
    </Styled.Center>
  )
}

export { LoadingPage }
