import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { createReduxEnhancer } from '@sentry/react'

import { middleware } from '@/store/middleware'
import { reducer } from '@/store/reducer'

const store = configureStore({
  enhancers: [createReduxEnhancer()],
  middleware,
  reducer,
})

setupListeners(store.dispatch)

export { store }
