import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Sizes } from '@/constants'

const WrapperStyled = styled(Grid)`
  display: flex;
  flex-direction: row;
`

const TitleStyled = styled(Grid)`
  align-items: center;
  background-color: rgb(255, 255, 255);
  font-size: 18px;
  font-weight: 500;
  border-bottom: ${({ theme }) => {
    return `1px solid ${theme.palette.divider}`
  }};
  display: flex;
  height: ${Sizes.titleAdjustment};
  padding: ${({ theme }) => {
    return `${theme.spacing(2)}`
  }};
`

const ContentStyled = styled(Grid)`
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: rgb(250, 250, 250);
`

export { ContentStyled, TitleStyled, WrapperStyled }
