import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import type { RootState } from '@/store'

const initialState = {
  // Should be true if there's a new changelog the user hasn't seen or localstorage is empty
  shouldOpenChangelog: true,
}

const changelogSlice = createSlice({
  initialState,
  name: 'changelog',
  reducers: {
    setShouldOpenChangelog: (state, action: PayloadAction<boolean>) => {
      state.shouldOpenChangelog = action.payload
    },
  },
})

const selectShouldOpenChangelog = (state: RootState) => {
  return state.changelog.shouldOpenChangelog
}

const { setShouldOpenChangelog } = changelogSlice.actions
const { reducer: changelogReducer } = changelogSlice

export { changelogReducer, selectShouldOpenChangelog, setShouldOpenChangelog }
