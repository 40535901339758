import { Avatar, Chip } from '@mui/material'
import { type Theme, css, styled } from '@mui/material/styles'

import { CompanyColors } from '@/constants/company-colors'

type AvatarStyledProps = {
  $size: number
  theme: Theme
}
const AvatarStyled = styled(Avatar)(({ $size, theme }: AvatarStyledProps) => {
  return css`
    width: ${$size}px;
    height: ${$size}px;
    margin-right: ${theme.spacing(1)};
  `
})

const ChipStyled = styled(Chip)<{ $type?: string }>`
  border: 0;
  ${({ $type }) => {
    if ($type) {
      return `
      background-color: ${CompanyColors[$type]?.backgroundColor};
      color: ${CompanyColors[$type]?.color};
    `
    }
  }}
`
export { AvatarStyled, ChipStyled }
