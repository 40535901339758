import { Typography, type TypographyProps } from '@mui/material'

const H1 = ({ children, ...rest }: TypographyProps) => {
  return (
    <Typography {...rest} variant='h1'>
      {children}
    </Typography>
  )
}

export { H1 }
