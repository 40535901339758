import { ListItemText } from '@mui/material'
import { styled } from '@mui/material/styles'

const ItemTextStyled = styled(ListItemText)`
  span {
    font-weight: 500;
    font-size: 0.9rem;
  }
`

export { ItemTextStyled }
